<template>
  <div class="m-0 p-0">
    <b-row class="m-1">
      <b-col class="d-flex justify-content-end">
        <b-button
          variant="primary"
          :disabled="isResponsibleAccount"
          @click="openModalAddScore()"
        >
          <feather-icon icon="PlusIcon" />
          Add </b-button>
      </b-col>
    </b-row>
    <b-row>
      <table class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th><span
              style="color:#822a3a;"
              class="font-weight-bolder"
            >EQUIFAX</span></th>
            <th><span
              style="color:#0566b7;"
              class="font-weight-bolder"
            > Experian</span></th>
            <th><span>Trans<span style="color:#4d917a">Union</span></span></th>
            <th>Created By</th>
            <th>Updated By</th>
            <th><center>Actions</center></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(score,i) in scores"
            :key="i"
          >
            <td>{{ score.date }}</td>
            <td><span
              style="color:#822a3a;"
              class="font-weight-bolder"
            >{{ score.equifax }}</span></td>
            <td><span
              style="color:#0566b7;"
              class="font-weight-bolder"
            >{{ score.experian }}</span></td>
            <td><span style="color:#4d917a">{{ score.transunion }}</span></td>
            <td>
              <b-col>
                <b-row>{{ score.username }}</b-row>
                <b-row>{{ score.created_at }}</b-row>
              </b-col>
            </td>
            <td>
              <b-col>
                <b-row>{{ score.updatename }}</b-row>
                <b-row>{{ score.updated_at }}</b-row>
              </b-col>
            </td>
            <td>
              <b-row>
                <b-col>
                  <center>
                    <feather-icon
                      v-if="!isResponsibleAccount"
                      icon="EditIcon"
                      class="text-warning cursor-pointer"
                      size="20"
                      @click="openModalAddScore(score.id)"
                    />
                  </center>
                </b-col>
                <b-col>
                  <center>
                    <feather-icon
                      v-if="!isResponsibleAccount"
                      icon="Trash2Icon"
                      class="text-danger cursor-pointer"
                      size="20"
                      @click="deletedScore(score.id)"
                    />
                  </center>
                </b-col>
              </b-row>
            </td>
          </tr>
        </tbody>
      </table>
    </b-row>
    <modal-add-score
      v-if="activeModalAddScore"
      :idscore="score"
      @close="closeModalAddScore"
      @refresh="refreshScores"
    />
  </div>
</template>
<script>
// vuex
import { mapActions, mapGetters } from 'vuex';
// modals
import ModalAddScore from '@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalAddScore.vue';
// service
import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services';

export default {
  components: { ModalAddScore },
  data() {
    return {
      activeModalAddScore: false,
      score: null,
    };
  },
  methods: {
    openModalAddScore(score = null) {
      this.score = score;
      this.activeModalAddScore = true;
    },
    closeModalAddScore() {
      this.activeModalAddScore = false;
    },
    async deletedScore(idscore) {
      const params = {
        id_user: this.currentUser.user_id,
        idscore,

      };
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await ClientDashboardService.deleteScore(params);
          await this.refreshScores();

          this.showSuccessSwal();
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    refreshScores() {
      this.getScores(this.leadId);
    },
    ...mapActions({
      getScores: 'ParagonClientDashboard/getScores',
    }),
  },
  computed: {
    ...mapGetters({
      scores: 'ParagonClientDashboard/getScores',
      currentUser: 'auth/currentUser',
      leadId: 'ParagonClientDashboard/getLeadId',
    }),
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
};
</script>
